/* Blog Single */
.blog-single{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; 
    gap: 2rem;
} 

/* Blog Container */
.blog-single .blog-container{
    -webkit-box-flex: 1; 
    -ms-flex: 1 1 75rem; 
        flex: 1 1 75rem;
}

/*----- 1- Blog Info -----*/
/* Blog Image container */
.blog-info .image{ 
    height: 40rem; 
    position: relative;
}
  
/* Blog Image */
.blog-info .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: var(--border-radius);
}
  
/* Blog Content */
.blog-info .content{
    padding: 2rem 0;   
}
  
/* Blog Details */
.blog-info .details{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
}

/* Blog Category */
.blog-info .category{
    background-color: var(--navy);
    color: var(--white);
    font-size: 1.6rem;
    text-align: center;
    padding: 0.5rem 1rem;
}

/* Blog Detail Item */
.blog-info .details h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.6rem;
    color: var(--grey);
}

/* Blog Detail Icon */
.blog-info .details h3 .icon{
    color: var(--navy);
}

/* Blog Detail Text */
.blog-info .details h3 span{
    font-weight: 400;
}
  
/* Blog Heading */
.blog-info .content .main-heading{
    display: inline-block;
    font-size: 3.5rem;
    font-weight: 600;
    color: var(--dark-black);
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}
  
/* Blog Paragraph */
.blog-info p{
    padding-bottom: 1.5rem;
    font-size: 1.5rem;
    color: var(--grey);
    line-height: 1.7;
}
  
/* Blog Important Info */
.blog-info .important{
    padding: 2rem;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--white);
    font-style: italic;
    line-height: 1.8;
    border-left: 0.5rem solid var(--dark-blue);
    margin-bottom: 2rem;
    margin-left: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2rem;
    background-color: var(--dark-black);
}

/* Blog Important Icon */
.blog-info .important .icon{
    font-size: 12rem;
    line-height: 1;
    color: var(--dark-blue);
}

/* Blog Sub Heading */
.blog-info .sub-heading{
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--dark-black);
    padding-bottom: 0.5rem; 
}

/* Blog Points List */
.blog-info .blog-points ul{
    list-style-type: none;
}

/* Blog Point */
.blog-info .blog-points ul li {

    font-size: 1.5rem;
  
    color: var(--grey);
  
    gap: 1rem;
  
    padding-bottom: 1rem;
  
    line-height: 1.7;
  
  }
  
  
  
  .blog-info .blog-points ul li strong {
  
    color: var(--dark-black);
  
    font-size: 1.7rem;
  
    padding-right: 1rem;
  
  }

/* Blog Point Icon */
.blog-info .blog-points ul li .icon{
    height: 1.5rem;
    width: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--navy);
}