.blog .box-container{
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33rem, 1fr));
  gap: 2rem;
} 

.blog-item{
  -webkit-box-shadow: var(--box-shadow);
          box-shadow: var(--box-shadow);
  background-color: var(--white); 
  overflow: hidden;
  border: var(--border)
}

.blog-item .image{
  height: 25rem;
  position: relative;
}

.blog-item .image img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blog-item .date{
  position: absolute;
  bottom: 0;
  left: 0;
  color: var(--white);
  background-color: var(--navy);
  opacity: 0.8;
  font-size: 1.7rem;
  line-height: 1.6;
  padding: 0.5rem 1rem;
  width: 10rem;
  text-align: center;
}

.blog-item .content{
  padding: 2rem 1rem;
}

.blog-item .intro{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
     align-items: center;
  margin-bottom: 1rem;
}

.blog-item .intro .category{
  display: inline-block; 
  font-size: 2rem;
  color: var(--navy);
  border: 0.2rem solid var(--navy);
  padding: 0.7rem 1.5rem;
  border-radius: 5rem;
}

.blog-item .intro span{
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--navy);
}

.blog-item .content .main-heading{
  display: inline-block;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--dark-black);
}

.blog-item .content .main-heading:hover{
  color: var(--dark-blue);
}

.blog-item .content p{
  padding-top: 1rem;
  font-size: 1.5rem;
  color: var(--light-black);
  line-height: 1.5;
}