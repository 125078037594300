.controls .button{
    display: inline-block;
    pointer-events: auto;
	cursor: pointer;
	border: none;
	padding: 1.2rem 2rem;
	position: relative;
	text-transform: uppercase;
	letter-spacing: 0.05rem;
	font-weight: 700;
	font-size: 1.6rem; 
    color: var(--navy);
}

.controls .button span{
    content: "";
    position: absolute;
    height: 100%;
    width: 25%;
    background-color: var(--navy);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    transform-origin: top;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    z-index: -1;
}

.controls .button span:nth-child(1){
    left: 0;
    top: 0;
}

.controls .button span:nth-child(2){
    left: 25%;
    top: 0;

}

.controls .button span:nth-child(3){
    left: 50%;
    top: 0;
}

.controls .button span:nth-child(4){
    left: 75%;
    top: 0;
}

.controls .button:hover{
    cursor: pointer;
    color: var(--white);
}

.controls .button:hover span:nth-child(1){
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
}

.controls .button:hover span:nth-child(2){
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    transition-delay: 0.15s;
}

.controls .button:hover span:nth-child(3){
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    transition-delay: 0.3s;
}

.controls .button:hover span:nth-child(4){
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    transition-delay: 0.45s;
}

.controls .button.active{
    cursor: pointer;
	color: var(--white);
	background: var(--navy);
}


 