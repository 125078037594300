.heading{
  padding-bottom: 2rem;
}
  
.heading h1 {
  position: relative;
  text-transform: capitalize;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 4rem;
  color: var(--dark-black);
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; 
}

.heading h1 span {
  display: block;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2.5;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  padding-left: 0.25rem;
  padding-bottom: 1rem;
  color: rgba(0, 0, 0, 0.4);
}

.heading h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6rem;
  height: 0.2rem;
  background-color: var(--navy);
}
