.services .box-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.service-item{
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 33rem; 
        flex: 1 1 33rem;
    background-color: var(--white);
    padding: 4rem 3rem;
    text-align: center;
    border-radius: 0.5rem;
    overflow: hidden;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
}

.service-item::before{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    bottom: calc(-100% + 0.5rem);
    background-color: var(--dark-blue);
    z-index: 1;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.service-item:hover::before{
    bottom: 0;
}

.service-item .content{
    position: relative;
    z-index: 2;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.service-item .icon{
    font-size: 4rem;
    color: var(--navy);
}

.service-item h3{
    font-size: 2.5rem;
    color: var(--dark-black);
    padding: 1.5rem 0;
}

.service-item p{
    font-size: 1.6rem;
    color: var(--light-black);
    line-height: 1.6;
}

.service-item:hover h3{
    color: var(--white);
}

.service-item:hover .icon{
    color: var(--white);
}

.service-item:hover p{
    color: var(--white);
}


