.home{
    padding: 0rem 5%;
    margin-top: 0rem;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../assets/Home/Home-Image.png');
    background: -webkit-linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../assets/Home/Home-Image.png');
    background-position: center;
    background-size: cover; 
    background-repeat: no-repeat;
}   

.home .box-container{
    min-height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.home .content{ 
    text-align: center;
    width: 60rem;
}

.home .content h1{
    font-size: 5rem;
    font-weight: 600;
    color: var(--white);
    text-transform: capitalize;
}

.home .content h4{
    font-size: 3rem;
    color: var(--white);
    font-weight: 500;
    padding: 1rem 0;
}

.home .content p{
    font-size: 1.7rem;
    font-weight: 400;
    color: var(--light-white);
    padding-bottom: 2rem;
    line-height: 1.6;
}

 

 