/*--------------------- 0- Global CSS (Start) ---------------------*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
 
:root{
    --navy: #001D6E;
    --dark-blue: #7FB5FF;
    --light-blue: #C4DDFF;
    --dark-black: #1C1427;
    --light-black: #40394A; 
    --white: #fff;
    --light-white: #DFDFDE;
    --grey: #383D3B;
    --border: 0.1rem solid rgba(0, 0, 0, 0.1);
    --border-radius: 1rem;
    --box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

*{
    font-family: 'Roboto', sans-serif;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    
    border: none;
    outline: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

*::selection{
    color: var(--white);
    background-color: var(--dark-blue);
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

body{
  padding-left: 25rem;
}

section{
  padding: 2rem 5%;
  margin-top: 0.1rem;
  min-height: 100vh;
}

::-webkit-scrollbar{
  width: 0.8rem;
}

::-webkit-scrollbar-thumb{
  border-radius: 1rem;
  background-color: var(--navy);
}

::-webkit-scrollbar-track{
  background: var(--dark-blue);
}

/*--------------------- Responsive (Start) ---------------------*/
@media (max-width: 991px){
  html{
      font-size: 55%;
  }
}

@media (max-width: 768px){

  section, header{
      padding: 2rem;
  }

}

@media (max-width: 450px){
  html{
      font-size: 50%;
  }

  section, header{
      padding: 1rem;
  }



}

/*--------------------- Responsive (End) ---------------------*/
