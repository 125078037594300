
.about h5{
    font-size: 3rem;
    color: var(--navy);
    text-align: center;
    border-bottom: 0.2rem solid var(--dark-blue);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 1rem 0;
    padding-bottom: 1rem;
}

/*--------------- Personal Info ---------------*/
.about .box-container{
    padding-bottom: 2rem;
}

.about .text{
    padding: 2rem 0;
}
 
.about .text h2{
    font-size: 3rem;
    color: var(--navy);
    padding-bottom: 1rem;
}

.about .text > p{
    font-size: 1.6rem;
    color: var(--grey);
    line-height: 1.6;
    padding-bottom: 1rem;
}

.about .text .about-info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1rem;
}

.about .text .about-info h4{
    font-size: 1.6rem;
    color: var(--navy);
}

.about .text .about-info span{
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--grey);
}

.about .counting{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1rem;
    background-color: var(--navy);
    border: 1rem;
    overflow: hidden;
}

.about .counting .box{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 18rem;
        flex: 1 1 18rem;
    height: 15rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    padding: 2rem;
}

.about .counting .box span{
    font-size: 5rem;
    font-weight: 500;
    color: var(--white);
    padding-bottom: 0.5rem;
}

.about .counting .box h3{
    font-size: 1.8rem;
    color: var(--white);
}

/*--------------- Skills ---------------*/
.skill-container{
    padding-bottom: 2rem;
}

.skills{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 1.5rem;
}
 
.skill-item{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 30rem;
        flex: 1 1 30rem;
}


.skill-item .box{
    padding:1rem 0;
}

 .skill-item .box h3{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
    justify-content: space-between;
    padding:.7rem 0;
    font-size: 1.7rem;
    color: var(--navy);
    font-weight: 400;
}

 .skill-item .box .progress-bar{ 
    width:100%;
    background: var(--light-blue);
    overflow:hidden;
    height:1rem;
    border-radius: 50rem;
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
}

 .skill-item .box .progress-bar span{
    display:block;
    height:100%;
    background: var(--navy);
}


/*--------------- Resume ---------------*/
.resume-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.education, .experience{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 35rem;
        flex: 1 1 35rem;
    background-color: var(--white);
    -webkit-box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    border: var(--border);
    padding: 1rem;
}

.education h6, 
.experience h6{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.education h6 span,
.experience h6 span{
    text-align: center;
    font-size: 2.5rem;
    color: var(--dark-black);
    margin-bottom: 1rem;
    border-bottom: 0.2rem solid var(--navy);
    padding-bottom: 0.5rem;
}

.education-item,
.experience-item{
    position: relative;
    padding: 0 1rem 1rem 3rem;
    border-left: 0.2rem solid var(--navy);
    margin-left: 2rem;
}
 
.education-item .icon,
.experience-item .icon{
    position: absolute;
    top: 0; 
    left: -2.5rem;
    height:5rem;
    width: 5rem;
    font-size: 2.5rem;
    line-height: 5rem;
    text-align: center;
    color:var(--white);
    background: var(--navy);
    padding: 1rem;
}

.education-item .content,
.experience-item .content{
    background: var(--white);
    padding: 2rem;
}

.education-item span,
.experience-item span{
    background: var(--navy);
    color: var(--white);
    padding: 0.5rem 1.5rem;
    font-size: 1.8rem;
    border-radius: 50rem;
}

.education-item h3, 
.experience-item h3{
    font-size: 2.5rem;
    color: var(--black);
    padding: 2rem 0;
}

.education-item p, 
.experience-item p{
    color: var(--grey);
    font-size: 1.6rem;
    line-height: 1.5;
}