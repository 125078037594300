.btn {
	display: inline-block;
	border: 2px solid var(--dark-blue);
	background:none;
	color: var(--dark-blue); 
	padding: 1rem 2rem;
	font-size: 1.8rem;
	text-transform: uppercase;
	cursor: pointer;
	-webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
	position: relative;
	color: var(--dark-blue);
	overflow: hidden;
	margin-top: 1rem;
	z-index: 1;
  }
  
  .btn:hover {
	color: white;
  }
  
  .btn:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--dark-blue);
	z-index: -1;
	border-radius: 50% 50% 0% 0%;
	height: 0%;
	-webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
  
  .btn:hover:before {
	height: 190%;
  }